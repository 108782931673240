import React from 'react';
import {
    ArrayField,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class ResultShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'durationTime') {
            return true;
        } else if (fieldName === 'answers') {
            fieldList.push(<ArrayField source="answers">
                <Datagrid linkType={false}>
                    <TextField label="Question" source="question"/>
                    <TextField label="Answer" source="answer"/>
                    <TextField label="Score" source="score"/>

                    <ArrayField source="answers">
                        <Datagrid linkType={false}>
                            <TextField label="Answer" source="answer"/>
                        </Datagrid>
                    </ArrayField>
                </Datagrid>
            </ArrayField>)
            return true
        } else if (fieldName === 'registrant') {
            fieldList.push(<ReferenceField source={'registrant.id'} reference={'registrants'} label={'Registrant'}>
                <TextField source="name" />
            </ReferenceField>);
            return true;
        }
        return false
    }
}

export default ResultShow;

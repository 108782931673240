export default {
    
    dateParser: (v) => {
        const d = new Date(v);
        if (isNaN(d)) {
            return;
        }
        return d;
    },

    dateFormatter: (v) => {
        console.log(v)
        if (!(v instanceof Date) || isNaN(v)) {
            v = new Date(v)
            if (!(v instanceof Date) || isNaN(v)) return;
        } 
        const pad = '00';
        const yy = v.getFullYear().toString();
        const mm = (v.getMonth() + 1).toString();
        const dd = v.getDate().toString();
        const hh = v.getHours().toString();
        const mmi = v.getMinutes().toString();
        const ss = v.getSeconds().toString();
        return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)} ${(pad + hh).slice(-2)}:${(pad + mmi).slice(-2)}:${(pad + ss).slice(-2)}`;
    },


}
import React from 'react';
import {
    Filter,
    TextInput,
    BooleanField,
    DateField,
    TextField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import PersonsExportAllActions from '../actions/PersonsExportAllActions';

class UserList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'password') {
            return true
        } else if (fieldName === 'disabled') {
            // fieldList.push(<BooleanField source={fieldName}/>)
            fieldList.push(<DateField source="createdAt"/>)
            return true
        // } else if (fieldName === 'quizResult') {
        //     fieldList.push(<TextField label="Score" source="quizResult.totalScore"/>)
        //     return true
        }
        return false
    }

    // customActions = (props) => {
    //     return <PersonsExportAllActions {...props}/>
    // }
}

export default UserList;

import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    DateInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    FileInput,
    FileField,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';
import DeleteButtonWithConfirmation from '../fields/DeleteButtonWithConfirmation';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';
import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//     toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//     },
// });


class CustomTableEdit extends React.Component {
    state = {
        data: null,
        hideDelete: false,
        popupDelete: false
     }

    CustomToolbar = props =>  {
        return (
        // <Toolbar {...props} classes={useStyles()}>
        <Toolbar {...props} >
            <SaveButton />
            {this.state.hideDelete ? null : (this.state.popupDelete ? <DeleteButtonWithConfirmation /> : <DeleteButton />) }

        </Toolbar>
        )
    };

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource.charAt(0).toUpperCase() + this.props.resource.slice(1)} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    customHandled(fields, fieldName, fieldList) {
        return false
    }
    

    generateFromFields(fields) {
        let that = this

        var fieldList = []
        console.log('PASSED HERE')
        console.log(fields)
        for (const fieldName in fields) {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            if (!this.customHandled(fields, fieldName, fieldList)) {

                if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined) {
                    fieldList.push(<SelectInput source={fieldName} label={fieldLabel} choices={this.transformInChoices(fields[fieldName].enum)} />)
                } else if (fields[fieldName].type === 'String') {
                    fieldList.push(<TextInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'Number') {
                    fieldList.push(<NumberInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'Date') {
                    // fieldList.push(<DateInput source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                    fieldList.push(<DateInput source={fieldName} label={fieldLabel} parse={dateUtils.dateParser} />)
                    // fieldList.push(<DateTimeInput source={fieldName} label={fieldLabel} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    fieldList.push(<BooleanInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref)
                    fieldList.push(<ReferenceInput source={fieldName} label={fieldLabel} reference={plural} allowEmpty>
                        <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
                    </ReferenceInput>)
                } else if (fields[fieldName].type === 'Object') {
                    // fieldList.push(<ReferenceInput source={fieldName} label={fieldLabel} reference={plural} allowEmpty>
                    //     <SelectInput optionText="name" />
                    // </ReferenceInput>)
                    fieldLabel +=  ' (max: 20Mb)';
                    fieldList.push(<FileInput source={fieldName} label={fieldLabel}
                    // accept="application/vnd.android.package-archive application/zip">
                    // accept="application/*">
                    >
                            <FileField source="src" title="title" />
                        </FileInput>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type)

                    if (fields[fieldName].type[0].type === "ObjectId") {
                        const plural = utils.getPlural(fields[fieldName].type[0].ref)

                        fieldList.push(<ReferenceArrayInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                            <AutocompleteArrayInput optionText={utils.displayFieldForPlural(plural)} />
                        </ReferenceArrayInput>)
                    } else {
                        if ((fields[fieldName].type[0].children)) {
                            var subObject = that.generateFromFields(fields[fieldName].type[0].children)

                            fieldList.push(<ArrayInput source={fieldName}>
                                <SimpleFormIterator>
                                    {subObject}
                                </SimpleFormIterator>
                            </ArrayInput>)
                        } else {
                            var subObject = that.generateFromFields(fields[fieldName].type)

                            fieldList.push(<ArrayInput source={fieldName}>
                                <SimpleFormIterator>
                                    {subObject}
                                </SimpleFormIterator>
                            </ArrayInput>)
                        }
                    }


                    // fieldList.push(<ReferenceArrayInput label={fieldName} source={fieldName} reference={fieldName} allowEmpty>
                    //         <SelectArrayInput optionText="name" />
                    // </ReferenceArrayInput>)

                }
            }
        };
        return fieldList
    }

    componentDidMount() {
        let that = this

        return getFieldsForRessource(this.props.resource).then((fields) => {

            var fieldList = that.generateFromFields(fields)

            this.setState({
                data: <Edit {...this.props} title={<this.ShowTitle />}>
                <SimpleForm toolbar={< that.CustomToolbar />}>
                    {fieldList}
                </SimpleForm>
            </Edit>
            });
        });
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }

    transformInChoices(base) {
        let returnObject = []
        for (const obj in base) {
            returnObject.push({
                "id": base[obj],
                "name": base[obj]
            })
        }
        return returnObject;
    }

    transformInChoicesWithout(base, avoidList) {
        let returnObject = []
        for (const obj in base) {
            if (avoidList.indexOf(base[obj]) === -1) {
                returnObject.push({
                    "id": base[obj],
                    "name": base[obj]
                })
            }
        }
        return returnObject;
    }
    
}

export default CustomTableEdit;

import React from 'react';
import {
    BooleanField,
    DateField,
    TextField,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import PasswordField from "../fields/PasswordField";

class UserEdit extends CustomTableEdit {

    state = {
        ...super.state,
        popupDelete: true
    }

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'password') {
            fieldList.push(<PasswordField source={fieldName}/>)
            return true
        } else if (fieldName === 'disabled') {
            // fieldList.push(<BooleanField source={fieldName}/>)
            fieldList.push(<DateField source="createdAt"/>)
            return true
        } else if (fieldName === 'quizResult') {
            fieldList.push(<TextField label="Score" source="quizResult.totalScore"/>)
            return true
        }

        return false
    }

}

export default UserEdit;

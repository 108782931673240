import React from 'react';
import Typography from '@material-ui/core/Typography';

const PasswordField = ({ record }) => {

  return (
    <Typography
        component="span"
        variant="body2"
        className="Password"
        label="Password"
        record
    >**********
    </Typography>
  );
    
};

PasswordField.defaultProps = {
    addLabel: true,
};

PasswordField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
};

  export default PasswordField;

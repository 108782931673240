import React from 'react';
import { Admin, Resource } from 'react-admin';
import createHistory from 'history/createBrowserHistory';

import QuestionIcon from '@material-ui/icons/FileCopy';
import UserIcon from '@material-ui/icons/Lock';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import MyLoginPage from './pages/MyLoginPage';

import UserList from './lists/UserList';

import './App.scss';
import CustomTableCreate from './creates/CustomTableCreate';
import CustomTableEdit from './edits/CustomTableEdit';
import CustomTableShow from './shows/CustomTableShow';
import CustomTableList from './lists/CustomTableList';

import ButtercupTheme from './ButtercupTheme';

import QuestionCreate from './creates/QuestionCreate';
import QuestionShow from './shows/QuestionShow';
import QuestionList from './lists/QuestionList';
import QuestionEdit from './edits/QuestionEdit';

import ResultShow from './shows/ResultShow';
import ResultList from './lists/ResultList';

import UserShow from './shows/UserShow';
import UserEdit from './edits/UserEdit';
import UserCreate from './creates/UserCreate';

import RegistrantShow from './shows/RegistrantShow';
import RegistrantList from './lists/RegistrantList';

// const history = createHistory({ basename: process.env.REACT_APP_BASE_URL });
const history = createHistory();

class App extends React.Component {

  render() {

  const ressources = []

  ressources.push()

  return <Admin
    loginPage={MyLoginPage}
    history={history}
    theme={ButtercupTheme}
    authProvider={authProvider}
    dataProvider={dataProvider}>
    {permissions => [

    <Resource
    name={'questions'}
    create={QuestionCreate}
    list={QuestionList}
    edit={QuestionEdit}
    show={QuestionShow}
    icon={QuestionIcon} />,

    <Resource
    name={'results'}
    // create={CustomTableCreate}
    list={ResultList}
    // edit={CustomTableEdit}
    show={ResultShow}
    icon={QuestionIcon} />,

    <Resource
        name={'registrants'}
        // create={CustomTableCreate}
        list={RegistrantList}
        // edit={CustomTableEdit}
        show={RegistrantShow}
        icon={UserIcon} />,

  permissions === 'admin' || permissions === 'super-admin' ? <Resource
    name={'users'}
    create={permissions === 'admin' || permissions === 'super-admin' ? UserCreate : null}
    list={UserList}
    edit={permissions === 'admin' || permissions === 'super-admin' ? UserEdit : null}
    show={UserShow}
    icon={UserIcon} /> : null,
    ]}
  </Admin>
  }
}

require('dotenv').config()

export default App;

import React from 'react';
import {cloneElement} from 'react';
import {
    useListContext,
    TopToolbar,
    Button,
    sanitizeListRestProps,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';


const QuizResultExportAllActions = (props) => {

    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();

    const permissions = localStorage.getItem('permissions');

    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        {(permissions === 'admin' || permissions === 'super-admin') && <Button
            onClick={() => {

                var headers = new Headers({Accept: 'application/json'});
                const token = localStorage.getItem('token');
                headers.set('Authorization', `JWT ${token}`);


                fetch(`${process.env.REACT_APP_API_URL}/results/export`, {method: 'GET', headers: headers})
                    .then(response => response.blob())
                    .then((blob) => {

                        /* LAUNCH THE DOWNLOAD */
                        const fakeLink = document.createElement('a');
                        fakeLink.style.display = 'none';
                        document.body.appendChild(fakeLink);
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            // Manage IE11+ & Edge
                            window.navigator.msSaveOrOpenBlob(blob, `Quiz_result_export.xlsx`);
                        } else {
                            fakeLink.setAttribute('href', URL.createObjectURL(blob));
                            fakeLink.setAttribute('download', `Quiz_result_export.xlsx`);
                            fakeLink.click();
                        }

                    })
                    .catch((e) => {
                        console.log('Warning: Error: comment not approved');
                    });
                console.log('ApproveButton clicked')
            }}

            label="Export Results Statistics"
        >
            <DownloadIcon/>
        </Button>}

    </TopToolbar>)


};

export default QuizResultExportAllActions;

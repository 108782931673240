import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ArrayField,
    Datagrid,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';

class CustomTableShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource.charAt(0).toUpperCase() + this.props.resource.slice(1)} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    lastCheckBeforeValidate(props, data) {
        return new Promise((resolve, reject) => {
              resolve({ data: <Show {...this.props}  title={<this.ShowTitle />}>
                    <SimpleShowLayout>
                        {data}
                    </SimpleShowLayout>
            </Show>});
          });
    }

    customHandled(fields, fieldName, fieldList) {
        return false
    }


    generateFromFields(fields) {
        var fieldList = []
        console.log('PASSED HERE')
        console.log(fields)
        for (const fieldName in fields) {

            if (!this.customHandled(fields, fieldName, fieldList)) {
                if (fields[fieldName].type === 'String') {
                    fieldList.push(<TextField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Number') {
                    fieldList.push(<NumberField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Date') {
                    fieldList.push(<DateField source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    fieldList.push(<BooleanField source={fieldName} />)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref)
                    fieldList.push(<ReferenceField source={fieldName} reference={plural} link="show">
                        <TextField source={utils.displayFieldForPlural(plural)} />
                    </ReferenceField>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    if ((fields[fieldName].type[0].children)) {
                        var subObject = this.generateFromFields(fields[fieldName].type[0].children)

                        fieldList.push(<ArrayField source={fieldName}>
                            <Datagrid>
                                {subObject}
                            </Datagrid>
                        </ArrayField>)
                    } else {

                        console.log(fields[fieldName].type)
                        
                        var displayField = "name"
                        var ref = fieldName

                        if (fields[fieldName].type[0].type === "ObjectId") {
                            displayField = utils.displayFieldForPlural(utils.getPlural(fields[fieldName].type[0].ref))
                            ref = utils.getPlural(fields[fieldName].type[0].ref)
                        }
        
                        fieldList.push(<ReferenceArrayField source={fieldName} reference={ref}>
                            <SingleFieldList>
                                <ChipField source={displayField} />
                            </SingleFieldList>
                        </ReferenceArrayField>)
                    }
                }
            }
        };
        return fieldList
    }

    componentDidMount() {
        return getFieldsForRessource(this.props.resource).then((fields) => {
            let that = this

            var fieldList = that.generateFromFields(fields)
    
            this.lastCheckBeforeValidate(this.props, fieldList).then((data) => {
                this.setState(data);
            })
        });
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }
}

export default CustomTableShow;

import React from 'react';
import CustomTableShow from './CustomTableShow';
import {NumberField} from "react-admin";

class RegistrantShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'quizResult') {
            return true
        } else if (fieldName === 'score') {
            fieldList.push(<NumberField label="Score" source="score"/>)
            return true
        }
        return false
    }
}

export default RegistrantShow;

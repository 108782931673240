import React from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    BooleanInput,
    required,
    SelectInput,
    NumberInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class QuestionEdit extends CustomTableEdit {

    answerValidation = (value, allValues) => {
        console.log(value)
        console.log(allValues)
        let containsCorrectAnswer = false

        if (allValues && allValues.type === 'open-question') {
            containsCorrectAnswer = true;
            allValues.answers = [];
        } else {
            for (const i in value) {
                const answer = value[i];

                if (answer && answer.rightAnswer) {
                    containsCorrectAnswer = true;
                    break;
                }
            }
            if (!containsCorrectAnswer) {
                return 'Question needs to contain at least 1 correct answer'
            }
        }
        return undefined;
    };

    validateAnswers = [this.answerValidation];

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'title') {
            fieldList.push(<TextInput label="Title" source={fieldName} multiline={true} fullWidth={true}/>)
            return true;
        } else if (fieldName === 'type') {
            fieldList.push(<SelectInput label="Type" source={fieldName}
                                        choices={this.transformInChoices(fields[fieldName].enum)}/>)
            return true;
        } else if (fieldName === 'answers') {
            fieldList.push(<ArrayInput source="answers" label="Answers" validate={this.validateAnswers}>
                <SimpleFormIterator>
                    <TextInput label="Answer" source="answer" validate={required()} multiline={true} fullWidth={true}/>
                    <BooleanInput label="Right Answer" source="rightAnswer"/>
                    <NumberInput label="Order" source="order"/>
                    {/*<TextInput label="Right Answer(if it's an open question)" source="rightAnswerString" multiline={true} fullWidth={true} />*/}
                </SimpleFormIterator>
            </ArrayInput>)

            return true;
        }
        return false;
    }

}

export default QuestionEdit;

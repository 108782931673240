import React from 'react';
import PasswordField from '../fields/PasswordField';
import CustomTableShow from './CustomTableShow';
import {DateField, TextField} from "react-admin";

class UserShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'password') {
            fieldList.push(<PasswordField source={fieldName}/>)
            return true
        } else if (fieldName === 'createdAt') {
            fieldList.push(<DateField source="createdAt"/>)
            return true
        } else if (fieldName === 'quizResult') {
            fieldList.push(<TextField label="Score" source="quizResult.totalScore"/>)
            return true
        }
        return false
    }
}

export default UserShow;

import React from 'react';
import {
    Login
} from 'react-admin';

// import login_background from '../assets/login_background.webp'

const MyLoginPage = () => (
    <Login
        // backgroundImage="https://atelierbraam.com/wp-content/uploads/2019/05/Illustration-homepage-textes-min.jpg.webp"
        // backgroundImage="https://atelierbraam.com/wp-content/uploads/2019/05/Illustration-homepage-textes-min.jpg.webp"
        // backgroundImage="radial-gradient(circle at 50% 14em, #da2a1d 0%, #00023b 60%, #00023b 100%)"
        // backgroundImage={login_background}
    />
  );

  export default MyLoginPage;

import React from 'react';
import {
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    TextField,
    BooleanField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class QuestionList extends CustomTableList {

    PostShow(props) {
        console.log(props);
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="answers">
                    <Datagrid linkType={false}>
                        <TextField label="Answer" source="answer"/>
                        <BooleanField label="Right Answer" source="rightAnswer"/>
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }
}

export default QuestionList;

export default {

    getPlural: (sigularWord) => {
        var lastChar = sigularWord.substr(sigularWord.length - 1);

        if (lastChar === 'y') {
            return `${sigularWord.substring(0, sigularWord.length -1)}ies`;
        } else if (lastChar === 'z') {
            return `${sigularWord.substring(0, sigularWord.length)}zes`;
        }
        return `${sigularWord}s`;
    },

    displayFieldForPlural: (plural) => {
        var optionText = "name"

        if (plural === 'questions') {
            optionText = "title"
        }
        return optionText
    }
}

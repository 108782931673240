import React from 'react';
import {
    Filter,
    TextInput,
    NumberField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import PersonsExportAllActions from '../actions/PersonsExportAllActions';

class RegistrantList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'quizResult') {
            return true
        } else if (fieldName === 'score') {
            fieldList.push(<NumberField label="Score" source="score"/>)
            return true
        }
        return false
    }

    // customActions = (props) => {
    //     return <PersonsExportAllActions {...props}/>
    // }
}

export default RegistrantList;

import { stringify } from 'query-string';
import { fetchUtils, HttpError } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export const httpClientFile = async (url, fileName, httpVerb, params) => {
    return fetch(params.data[fileName].src)
    .then(r => {
        return r.blob()
    }).then(blob => {
        const formData  = new FormData();
        formData.append("data", JSON.stringify(params.data));
        formData.append(fileName, blob)

        const token = localStorage.getItem('token');
        return fetch(url,  {
            method: httpVerb,
            body: formData,
            headers: new Headers(
                {
                    'Authorization': `JWT ${token}`
                }
            )
        })
    }).then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
        })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
}

export const httpClientSpeakerFiles = async (url, httpVerb, params) => {
    return httpClientFile(url, 'speakerPicture', httpVerb, params)
};

export const httpClientSerieFiles = async (url, httpVerb ,params) => {
    return httpClientFile(url, 'seriePicture', httpVerb, params)
};

export const httpClientAnnouncementFiles = async (url, httpVerb ,params) => {
    return httpClientFile(url, 'announcementImage', httpVerb, params)
};

export const httpClientHomepageFiles = async (url, httpVerb ,params) => {
    return httpClientFile(url, 'hdePromoImage', httpVerb, params)
};

export const httpClientVideoFiles = async (url, httpVerb ,params) => {
    return httpClientFile(url, 'videoThumbnail', httpVerb, params)
};

export const httpClientSubCategoryFile = async (url, params) => {
    const formData  = new FormData();
    formData.append("data", JSON.stringify(params.data));

    if (params.data.icon.rawFile == null) {

    } else {
        const data = await fetch(params.data.icon.src)
        const blob = await data.blob()
        formData.append(`icon`, blob)
    }

    if (params.data.background.rawFile == null) {

    } else {
        const data = await fetch(params.data.background.src)
        const blob = await data.blob()
        formData.append(`background`, blob)
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: 'POST',
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};


export const httpClientSubCategoryUpdateFile = async (url, params) => {
    const formData  = new FormData();

    console.log("httpClientSubCategoryUpdateFile")

    formData.append("data", JSON.stringify(params.data));

    if (params.data.icon.rawFile == null) {

    } else {
        const data = await fetch(params.data.icon.url)
        const blob = await data.blob()
        formData.append(`icon`, blob)
    }

    if (params.data.background.rawFile == null) {

    } else {
        const data = await fetch(params.data.background.url)
        const blob = await data.blob()
        formData.append(`background`, blob)
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: 'PUT',
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};


export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;


        return httpClient(url).then(({ headers, json }) => (
            {
                data: json,
                total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
            }
        ));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    getSchema: (resource) => {

        const url = `${apiUrl}/${resource}/schema`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    update: (resource, params) => {

        if (params.data.speakerPicture != null && params.data.speakerPicture.rawFile != null) {
            return httpClientSpeakerFiles(`${apiUrl}/${resource}/${params.id}`, 'PUT', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.seriePicture != null && params.data.seriePicture.rawFile != null) {
            return httpClientSerieFiles(`${apiUrl}/${resource}/${params.id}`, 'PUT', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.announcementImage != null && params.data.announcementImage.rawFile != null) {
            return httpClientAnnouncementFiles(`${apiUrl}/${resource}/${params.id}`, 'PUT', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.hdePromoImage != null && params.data.hdePromoImage.rawFile != null) {
            return httpClientHomepageFiles(`${apiUrl}/${resource}/${params.id}`, 'PUT', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.videoThumbnail != null && params.data.videoThumbnail.rawFile != null) {
            return httpClientVideoFiles(`${apiUrl}/${resource}/${params.id}`, 'PUT', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        }  else if (params.data.icon != null) {
            return httpClientSubCategoryUpdateFile(`${apiUrl}/${resource}/${params.id}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        }  else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }, 
            }))
        }
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        if (params.data.speakerPicture != null && params.data.speakerPicture.rawFile != null) {
            return httpClientSpeakerFiles(`${apiUrl}/${resource}`, 'POST', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.seriePicture != null && params.data.seriePicture.rawFile != null) {
            return httpClientSerieFiles(`${apiUrl}/${resource}`, 'POST', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.announcementImage != null && params.data.announcementImage.rawFile != null) {
            return httpClientAnnouncementFiles(`${apiUrl}/${resource}`, 'POST', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.videoThumbnail != null && params.data.videoThumbnail.rawFile != null) {
            return httpClientVideoFiles(`${apiUrl}/${resource}`, 'POST', params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        }  else if (params.data.icon != null) {
            return httpClientSubCategoryFile(`${apiUrl}/${resource}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        }
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    uploadFile: async (folder, fileInfo) => {

        // const data = await fetch(fileInfo.path);
        // // const data = await fetch(fileInfo.src);
        // // const file = await fetch(data.url);
        // const blob = await data.blob();

        const url = `${apiUrl}/files/${folder}`;

        // console.log('fileInfo');
        // console.log(fileInfo);

        // console.log('data');
        // console.log(data);

        // console.log('blob');
        // console.log(blob);

        // // console.log('file');
        // // console.log(file);
        const formData = new FormData();
        formData.append('files', fileInfo);

        const token = localStorage.getItem('token');

        return httpClient(url, {
            method: 'POST',
            headers: new Headers(
                {
                    // "Content-Type": 'multipart/form-data',
                    'Authorization': `JWT ${token}`
                }
            ),
            body: formData

        }).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    }
};

import React from 'react';
import {
    TextInput,
    BooleanInput,
    SelectInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

class UserCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        const role = localStorage.getItem('permissions')
        let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
        fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

        if (fieldName === 'disabled' || fieldName === 'quizResult') {
            return true
        } else if (fieldName === 'role') {
            fieldList.push(<SelectInput label="Role" source={fieldName}
                                        choices={this.transformInChoices(fields[fieldName].enum)}/>)
            return true;
        } else if (fieldName === 'leaderboardBlacklisted') {
            if (role === 'super-admin') {
                fieldList.push(<BooleanInput source={fieldName} label={fieldLabel}/>)
            }
            return true;
        }
        return false
    }

}

export default UserCreate;

import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    FunctionField,
    DateField,
    SelectInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

import QuizResultExportAllActions from '../actions/QuizResultExportAllActions';
import utils from '../utils/utils';

class ResultList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'durationTime') {
            return true;
        } else if (fieldName === 'duration') {
            fieldList.push(<TextField label="Duration" source="duration" />)
            return true;
        } else if (fieldName === 'totalScore') {
            fieldList.push(<NumberField label="Score" source="totalScore" />)
            return true;
        } else if (fieldName === 'registrant') {
            const plural = utils.getPlural(fields[fieldName].ref);
            fieldList.push(<ReferenceField label="Registrant" source="registrant.id" reference={plural} link="show">
                <TextField source={utils.displayFieldForPlural(plural)} />
            </ReferenceField>);

            fieldList.push(<FunctionField label="Phone number" source="registrant" render={result => `${result.registrant.phone}`} />);
            return true;
        } else if (fieldName === 'answers') {
            fieldList.push(<DateField source="createdAt" showTime />)
            return true;
        }

        return false;
    }

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Registrant" source="registrant" reference="registrants" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <SelectInput label="Type" source="type" allowEmpty
                choices={[
                    { id: 'multiple-choices', name: 'Multiple choices' },
                    { id: 'true-or-false', name: 'True or false' },
                    { id: 'open-question', name: 'Open question' },
                    { id: 'organize-order', name: 'Organize order' },
                    { id: 'interactive-map', name: 'Interactive map' },
                ]} />
        </Filter>
    );


    PostShow(props) {
        console.log(props);
        console.log(props.record);
        console.log('-------------------');

        if (props.record && window.location.search) {
            let search = window.location.search;
            search = search.substr(1);
            let arr = search.split('&');
            let obj = {};
            let newArr = [];
            arr.forEach((item, index) => {
                newArr = item.split('=');
                obj[newArr[0]] = newArr[1];
            })

            if (obj.filter) {
                obj.filter = decodeURIComponent(obj.filter);
                obj.filter = JSON.parse(obj.filter);

                if (obj.filter.type) {
                    let answers = [];
                    let score = 0;

                    props.record.answers.map((answer) => {
                        if (answer.type === obj.filter.type) {
                            answers.push(answer);
                            score += answer.score;
                        }
                    })
                    answers = answers?.filter((answer) => answer.type === obj.filter.type);
                    props.record.answers = answers;
                    props.record.duration = '-';
                    props.record.totalScore = score;
                }
            }
        }


        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="answers">
                    <Datagrid linkType={false}>
                        <TextField label="Question" source="question" />
                        <TextField label="Answer" source="answer" />
                        <TextField label="Score" source="score" />

                        <ArrayField source="answers">
                            <Datagrid linkType={false}>
                                <TextField label="Answer" source="answer" />
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return <QuizResultExportAllActions {...props} />
    }
}

export default ResultList;

import React from 'react';
import {
    ArrayField,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class QuestionShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'questions') {
            fieldList.push(<ArrayField source="questions">
                <Datagrid linkType={false}>
                    <TextField label="Question" source="question"/>
                    <ArrayField source="answers">
                        <Datagrid linkType={false}>
                            <TextField label="Answer" source="answer"/>
                            <BooleanField label="Right Answer" source="rightAnswer"/>
                            <NumberField label="Order" source="order"/>
                        </Datagrid>
                    </ArrayField>
                </Datagrid>
            </ArrayField>)
            return true
        }
        return false
    }
}

export default QuestionShow;
